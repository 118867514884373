import React, { Component } from "react";
import {
  Text,
  View,
  Image,
  ScrollView,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  Linking,
  TouchableWithoutFeedback,
  Modal as ReactModal,
} from "react-native";
import {
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  appStyles,
  button,
  subscribePlans,
  Colors,
  FontFamily,
  FontWeight,
  postSingle,
} from "../../styles/appStyles";
import { helpers } from "../../utils/helpers";
import apiHelpers from "../../utils/api-helpers";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Cookies from "universal-cookie";
import ErrorModal from "../error-modal";
import { removeUserToken } from "../../../redux/User/user.actions";
import PageBreak from "../page-break";
import CustomImage from "../custom-image";
import ButtonGradient from "../button-gradient";
import ActionBox from "../action-box";
import SubscriptionPlans from "../subscription-plans";
import Modal from "../modal";
import Button from "../button";
import getImagePath from "../../utils/image-helper";
import moment from "moment";
import ContentLoader from "../content-loader";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import AuthenticationWrapper from "../authentication-wrapper";
import { ChargebeeController } from "../../utils/chargebee";


const defaultCompany = getImagePath("icons/default-company-logo.png");
const defaultCompanyLight = getImagePath(
  "icons/default-company-logo-white.png"
);
const cookies = new Cookies();
const elementBG = getImagePath("img/welcome_bg.png");
const checkIcon = getImagePath("icons/check.png");

const studenOrangeIcon = getImagePath("icons/studenOrangeIcon.png");
const giftOrangeIcon = getImagePath("icons/giftOrangeIcon.png");
const accesOrangeIcon = getImagePath("icons/accesOrangeIcon.png");
const greenTick = getImagePath("icons/green-tick.png");

const corporateData = {
  id: "team-plan-page",
  name: "Team",
  invoice_name: "Team",
  description: {
    access_desc: [
      "Each member gets the complete TMC experience.",
      "Manage accounts easily - Reassessing, revoke access to accounts.",
    ],
  },
  is_corporate: true,
};

export class SubscriptionPricingPlan extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    const { isAuthenticated, fetchToken } = this.props.auth;
    let token = webView
      ? isAuthenticated
        ? fetchToken
        : false
      : this.props.user?.userData?.data?.token;
    let tokenSet = token == undefined || token == "" ? false : true;
    this.state = {
      token: tokenSet ? token : false,
      pageLoader: this.props.plansData ? false : true,
      showErrorModal: false,
      plansData: { individual: [] },
      userSubscription: this.props.planPage
        ? this.props.userSubscription
        : false,
      teamSubscription: this.props.planPage
        ? this.props.teamSubscription
        : false,
      country: this.props.planPage ? this.props.country : false,
      creditPlan: this.props.planPage ? this.props.creditPlan : false,
      isButtonClicked: false,
      cb_handle: null,
      showButtonLoader: false,
      userSubscriptionExpired: false,
      locationInfo: false,
    };
  }

  componentWillUnmount = () => {};

  setAuthToken = () => {
    this.props.auth?.getIdTokenClaims().then((tokenClaims) => {
      if (tokenClaims == undefined) {
        this.setState({
          custom_user_id: false
        })
      }
      else {
        this.setState({
          custom_user_id: tokenClaims?.legacy_claims?.id
        })
      }
    })
  }

  componentDidMount = () => {
    ChargebeeController.activatedController().then((cb_handle) => {
      this.setState({ cb_handle: cb_handle });
      let countryInfo = null;
      if (
        cb_handle?.instance?.billingInfo?.billing_platform === "chargebee_india"
      ) {
        countryInfo = {
          code: "IN",
          name: "India",
        };
      } else if (cb_handle?.instance?.billingInfo) {
        countryInfo = {
          code: "US",
          name: "USA",
        };
      }

      if (countryInfo) {
        this.setState({
          locationInfo: { countryInfo: countryInfo },
        });
      }
    });

    if (this.props.planPage) {
      this.getSubscriptionPlans();
      if (this.state.token) {
        this.getUserInfo();
      }
    } else {
      const { token } = this.state;
      if (token) {
        this.getUserInfo();
      } else {
        if (this.props.setModalTitle) {
          this.props.setModalTitle("Select Plan");
        }
        if (this.props.isAdmin) {
          this.setState({
            plansData: { individual: [] },
            pageLoader: false,
          });
        } else {
          this.getSubscriptionPlans();
        }
        this.getLocationInfo();
      }
    }
    if(!this.state.custom_user_id){
      this.setAuthToken()
    }
  };

  getLocationInfo = () => {
    const { token } = this.state;
    let endpoint = `/location-info`;
    apiHelpers
      .apiRequest(endpoint, {}, "GET", true, token)
      .then((res) => {
        if (res.success) {
          if (res.data) {
            this.setState({ locationInfo: res.data });
          }
        }
      })
      .catch((error) => {
        console.log("Error=>> down:getLocationInfo:/location-info: ", error);
      });
  };

  componentDidUpdate = () => {
    if (this.props.planPage) {
      //
    } else {
      let webView = helpers.isWeb();
      const { isAuthenticated, fetchToken } = this.props.auth;
      let token = webView
        ? isAuthenticated
          ? fetchToken
          : false
        : this.props.user?.userData?.data?.token;
      if (
        !(
          this.props.searchQuery &&
          (this.props.searchQuery.includes("subscribe=") ||
            this.props.searchQuery.includes("buy-credits=true"))
        ) &&
        ((token && token != this.state.token) ||
          ((token == undefined || token == "") && this.state.token != false))
      ) {
        this.setState(
          {
            token: token == undefined || token == "" ? false : token,
            pageLoader: true,
          },
          () => {
            if (this.state.token) {
              this.getUserInfo(true);
            } else {
              if (this.props.setModalTitle) {
                this.props.setModalTitle("Select Plan");
              }
              if (this.props.isAdmin) {
                this.setState({
                  plansData: { individual: [] },
                  pageLoader: false,
                });
              } else {
                this.getSubscriptionPlans();
              }
            }
          }
        );
      }
    }
  };

  scrollToTop = (scroll) => {
    if (scroll) {
      if (helpers.isWeb()) {
        setTimeout(function () {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 2);
      }
    }
  };

  isObjectEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  }

  getUserInfo = (scroll) => {
    this.scrollToTop(scroll);
    const { token } = this.state;
    let endpoint = `/profile`;
    const { isAdmin, referenceId } = this.props;
    let body = {
      request_group: ["subscription", "profile"],
    };
    if (isAdmin) {
      body = {
        request_group: ["subscription", "profile"],
        is_admin: true,
        reference_id: referenceId,
      };
    }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.code == "access_denied") {
          // this.authTokenExpired();
          return false;
        }
        if (res.success) {
          this.setState({
            userSubscription:
              !res.data.subscription ||
              JSON.stringify(res.data.subscription) == "{}"
                ? false
                : res.data.subscription,
            teamSubscription:
              !res.data.team_subscription ||
              JSON.stringify(res.data.team_subscription) == "{}"
                ? false
                : res.data.team_subscription,
            userSubscriptionExpired:
              (res.data.team_subscription &&
                !this.isObjectEmpty(res.data.team_subscription)) ||
              (res.data.subscription &&
                !this.isObjectEmpty(res.data.subscription)) ||
              (res.data.expired_subscription &&
                !this.isObjectEmpty(res.data.team_subscription))
                ? true
                : false,
            userProfile: res.data,
          });
          if (this.props.setModalTitle) {
            if (
              !res.data.subscription ||
              JSON.stringify(res.data.subscription) == "{}"
            ) {
              this.props.setModalTitle("Select Plan");
            } else {
              this.props.setModalTitle("My Plan");
            }
          }
          if (isAdmin) {
            this.setState({
              plansData: { individual: [] },
              pageLoader: false,
            });
          } else {
            this.getSubscriptionPlans(res.data);
          }
        } else {
          if (this.props.setModalTitle) {
            this.props.setModalTitle("Select Plan");
          }
          console.log("Error=>>", res);
          if (isAdmin) {
            if (this.props.setNotAuthorized) {
              this.props.setNotAuthorized();
            }
          } else {
            this.setState({
              showErrorModal: true,
              pageLoader: false,
            });
          }
        }
      })
      .catch((error) => {
        if (this.props.setModalTitle) {
          this.props.setModalTitle("Select Plan");
        }
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
      });
  };

  getSubscriptionPlans = (profileData) => {
    const { token } = this.state;
    let endpoint = `/plans/default`;
    // let endpoint = `/plans/IN`;
    let body = {
      filters: {
        tmc_group: ["individual", "free", "exclusive"],
      },
      rank_sort: "ASC",
    };
    // if (profileData && profileData?.subscription?.plan) {
    //   body.filters.plan_gte = profileData.subscription.plan.id;
    // }
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, token)
      .then((res) => {
        if (res.success) {
          // let creditPlan = res.data.plans.credit[0];
          this.setState(
            {
              plansData:
                profileData && profileData?.team_subscription?.is_team_member
                  ? { individual: [] }
                  : res.data.plans,
              country: res.data.country,
              pageLoader: false,
              creditPlan: null,
            },
            () => {
              console.log("resP:", this.state.plansData);
            }
          );
        } else {
          console.log("Error=>>", res);
          this.setState({
            showErrorModal: true,
            pageLoader: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error=>>", error);
        this.setState({
          showErrorModal: true,
          pageLoader: false,
        });
      });
  };

  authTokenExpired = () => {
    if (helpers.isWeb()) {
      let cookieDate = new Date(Date.now() - 1);
      cookies.set("token", "", { path: "/", expires: cookieDate });
    } else {
      this.props.removeUserToken();
    }
    this.setState(
      {
        token: false,
        userLoggedin: false,
      },
      () => this.getSubscriptionPlans()
    );
  };

  showPageLoader = () => {
    const webView = helpers.isWeb();
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    if (this.props.homepage) {
      return <></>;
    }
    return <ContentLoader darkMode={darkMode} />;
  };

  navigateToTeamSubscription = () => {
    if (this.props.sidePanel) {
      this.props.navigatePage("/team-subscription");
    } else {
      this.props.navigateScreen("/team-subscription");
    }
  };

  isSubscriptionUnderDunning() {
    let { cb_handle } = this.state;
    if (cb_handle?.activeSubscription?.dunning_status == "in_progress") {
      return true;
    }
    return false;
  }

  navigateToTeamPage = () => {
    if (this.props.sidePanel) {
      this.props.navigatePage("/my-team");
    } else {
      this.props.navigateScreen("/my-team");
    }
  };

  renderCurrentTeamPlan = () => {
    const { plansData, teamSubscription } = this.state;
    let { darkMode, sidePanel, isAdmin, referenceId } = this.props;
    let darkModeOption = sidePanel
      ? helpers.isDarkMode(this.props?.app?.DarkMode)
      : darkMode;
    let userPlan = teamSubscription.plan;
    let ownerDesc = userPlan.description.owner_desc
      ? userPlan.description.owner_desc
      : userPlan.description.access_desc;
    let memberDesc = userPlan.description.member_desc
      ? userPlan.description.member_desc
      : userPlan.description.access_desc;
    return (
      <View>
        {/*<View style={[Margin.mb_2]}>
                    <Text style={[subscribePlans.planPrice, Margin.mt_0,{fontSize: 36, lineHeight: 43}]}>{teamSubscription.team_details.seats_purchased} member{teamSubscription.team_details.seats_purchased > 1 ? 's' : ''}</Text>
                </View>*/}
        {teamSubscription && teamSubscription.is_team_owner ? (
          <View>
            <View
              style={[
                Flex.row,
                Flex.alignCenter,
                Flex.justfiyCenter,
                Margin.mb_3,
              ]}
            >
              <CustomImage
                source={
                  teamSubscription.user_info.company_logo
                    ? teamSubscription.user_info.company_logo
                    : darkModeOption
                    ? defaultCompanyLight
                    : defaultCompany
                }
                require={true}
                style={{
                  width: 28,
                  height: 28,
                  resizeMode: "cover",
                  marginRight: 10,
                }}
                webStyle={{
                  width: 28,
                  height: 28,
                  objectFit: "cover",
                  marginRight: 10,
                }}
                altText={
                  teamSubscription.user_info.company_name
                    ? teamSubscription.user_info.company_name
                    : `${
                        teamSubscription.user_info.first_name != ""
                          ? teamSubscription.user_info.first_name
                          : teamSubscription.user_info.last_name
                      } Team`
                }
              />
              <Text
                style={[
                  {
                    fontFamily: FontFamily.abrilFatface,
                    fontSize: 20,
                    lineHeight: 24,
                  },
                  darkModeOption && { color: Colors.lightWhite },
                ]}
              >
                {teamSubscription.user_info.company_name
                  ? teamSubscription.user_info.company_name
                  : `${
                      teamSubscription.user_info.first_name != ""
                        ? teamSubscription.user_info.first_name
                        : teamSubscription.user_info.last_name
                    } Team`}
              </Text>
            </View>

            <View
              style={[
                Flex.row,
                Flex.alignCenter,
                Margin.mb_3,
                { justifyContent: "space-evenly" },
              ]}
            >
              <View style={{ marginRight: 20, alignItems: "center" }}>
                <Text
                  style={{
                    fontFamily: FontFamily.merriweather,
                    fontSize: 24,
                    lineHeight: 48,
                    color: darkModeOption
                      ? Colors.lightWhite
                      : "rgba(0, 0, 0, 0.7)",
                    letterSpacing: 0.3,
                  }}
                >
                  {teamSubscription?.plan.tmc_group == "team_credit"
                    ? teamSubscription?.team_details?.credits_available
                    : teamSubscription?.team_details?.seats_purchased}
                </Text>
                <Text
                  style={[
                    {
                      fontSize: 14,
                      lineHeight: 28,
                      fontFamily: FontFamily.regular,
                      color: "#8A8A8A",
                      fontWeight: "400",
                    },
                  ]}
                >
                  {teamSubscription?.plan.tmc_group == "team_credit"
                    ? "Credits Available"
                    : "Seats Purchased"}
                </Text>
              </View>
              <View style={{ marginRight: 20, alignItems: "center" }}>
                <Text
                  style={{
                    fontFamily: FontFamily.merriweather,
                    fontSize: 24,
                    lineHeight: 48,
                    color: darkModeOption
                      ? Colors.lightWhite
                      : "rgba(0, 0, 0, 0.7)",
                    letterSpacing: 0.3,
                  }}
                >
                  {teamSubscription?.team_details?.total_members}
                </Text>
                <Text
                  style={[
                    {
                      fontSize: 14,
                      lineHeight: 28,
                      fontFamily: FontFamily.regular,
                      color: "#8A8A8A",
                      fontWeight: "400",
                    },
                  ]}
                >
                  Members
                </Text>
              </View>
              <View style={{ marginRight: 20, alignItems: "center" }}>
                <Text
                  style={{
                    fontFamily: FontFamily.merriweather,
                    fontSize: 24,
                    lineHeight: 48,
                    color: darkModeOption
                      ? Colors.lightWhite
                      : "rgba(0, 0, 0, 0.7)",
                    letterSpacing: 0.3,
                  }}
                >
                  {teamSubscription?.team_details?.pending_invites}
                </Text>
                <Text
                  style={[
                    {
                      fontSize: 14,
                      lineHeight: 28,
                      fontFamily: FontFamily.regular,
                      color: "#8A8A8A",
                      fontWeight: "400",
                    },
                  ]}
                >
                  Invited
                </Text>
              </View>
            </View>

            {ownerDesc && (
              <View style={[Margin.mb_3]}>
                {ownerDesc.map((itemElement, index) => {
                  return (
                    <View style={[Margin.mt_1, Margin.mb_0]}>
                      <View style={[Flex.row, { alignItems: "flex-start" }]}>
                        <CustomImage
                          source={checkIcon}
                          require={true}
                          style={{
                            width: 20,
                            height: 14,
                            resizeMode: "contain",
                            marginTop: 3,
                          }}
                          webStyle={{
                            width: 20,
                            height: 14,
                            objectFit: "contain",
                            marginTop: 3,
                          }}
                        />
                        <Text
                          style={[
                            subscribePlans.planDesc,
                            helpers.isDesktop() &&
                              subscribePlans.planDescDesktop,
                            this.props.sidePanel &&
                              subscribePlans.planDescPanel,
                            Margin.ml_1,
                            { textAlign: "left", marginBottom: 0 },
                            darkModeOption && { color: Colors.lightWhite },
                          ]}
                        >
                          {itemElement}
                        </Text>
                      </View>
                    </View>
                  );
                })}
              </View>
            )}

            <View>
              <ButtonGradient
                title={"MANAGE MEMBERS"}
                rounded={true}
                fullWidth={true}
                disable={isAdmin ? true : false}
                rootStyle={{
                  btnWrapper: [
                    button.primaryGradientBtn,
                    { height: 46, borderRadius: 0, margin: 0 },
                  ],
                  btnText: [
                    button.primaryGradientText,
                    {
                      fontSize: helpers.isDesktop() ? 17 : 16,
                      fontWeight: "400",
                      paddingTop: helpers.isDesktop() ? 0 : 15,
                      paddingBottom: helpers.isDesktop() ? 0 : 15,
                    },
                  ],
                }}
                onClick={() => this.navigateToTeamPage()}
                darkMode={darkModeOption}
              />

              {this.isSubscriptionUnderDunning() && (
                <ButtonGradient
                  title={
                    this.state.isButtonClicked &&
                    (this.state.showButtonLoader ||
                      this.state.cb_handle?.instance?.siteConfig
                        ?.enableRedirectMode) == true ? (
                      <>
                        <ActivityIndicator
                          size={25}
                          color={
                            this.state.showButtonLoader ? "#000000" : "#FFFFFF"
                          }
                        />
                      </>
                    ) : (
                      "Renew"
                    )
                  }
                  rounded={true}
                  fullWidth={true}
                  disable={isAdmin ? true : false}
                  rootStyle={{
                    btnWrapper: [
                      button.primaryGradientBtn,
                      { height: 46, borderRadius: 0, margin: 0 },
                      {
                        marginTop: 5,
                      },
                    ],
                    btnText: [
                      button.primaryGradientText,
                      {
                        fontSize: helpers.isDesktop() ? 17 : 16,
                        fontWeight: "400",
                        paddingTop: helpers.isDesktop() ? 0 : 15,
                        paddingBottom: helpers.isDesktop() ? 0 : 15,
                      },
                    ],
                  }}
                  onClick={() => {
                    this.setState({ isButtonClicked: true });
                    ChargebeeController.activatedController().then(
                      (cb_handle) => {
                        cb_handle.hosted_page.collect_now();
                        this.setState({ showButtonLoader: true });
                      }
                    );
                  }}
                  darkMode={darkModeOption}
                />
              )}
            </View>
          </View>
        ) : (
          <View style={{ flex: 1 }}>
            {memberDesc &&
              memberDesc.map((itemElement, index) => {
                return (
                  <View style={[Margin.mt_1, Margin.mb_0]}>
                    <View style={[Flex.row, { alignItems: "flex-start" }]}>
                      <CustomImage
                        source={checkIcon}
                        require={true}
                        style={{
                          width: 20,
                          height: 14,
                          resizeMode: "contain",
                          marginTop: 3,
                        }}
                        webStyle={{
                          width: 20,
                          height: 14,
                          objectFit: "contain",
                          marginTop: 3,
                        }}
                      />
                      <Text
                        style={[
                          subscribePlans.planDesc,
                          helpers.isDesktop() && subscribePlans.planDescDesktop,
                          this.props.sidePanel && subscribePlans.planDescPanel,
                          Margin.ml_1,
                          { textAlign: "left", marginBottom: 0 },
                          darkModeOption && { color: Colors.lightWhite },
                        ]}
                      >
                        {itemElement}
                      </Text>
                    </View>
                  </View>
                );
              })}
          </View>
        )}
      </View>
    );
  };

  renderCurrentPlan = (giftPlan) => {
    const { plansData, userSubscription } = this.state;
    let userPlan = userSubscription.plan;
    let { darkMode, sidePanel } = this.props;
    let darkModeOption = sidePanel
      ? helpers.isDarkMode(this.props?.app?.DarkMode)
      : darkMode;
    return (
      <View>
        {Platform.OS != "ios" && userPlan.id != "institution-plan-inr" && (
          <>
            <View style={[Margin.mb_2]}>
              <Text
                style={[
                  subscribePlans.planPrice,
                  Margin.mt_0,
                  !helpers.isWeb() && { paddingTop: 10 },
                ]}
              >
                <Text style={[subscribePlans.symbol]}>
                  {userPlan.currency_code == "INR" ? "₹" : "$"}
                </Text>
                {userSubscription.subscription_amount_paid
                  ? userSubscription.subscription_amount_paid
                  : userSubscription.subscribed_plan_amount
                  ? userSubscription.subscribed_plan_amount
                  : userPlan.discount?.offer_price
                  ? userPlan.discount.offer_price
                  : userPlan.price}
              </Text>
            </View>

            {userPlan.description?.monthly_price && (
              <View
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  justifyContent: "center",
                  marginBottom: 25,
                }}
              >
                <View
                  style={{
                    backgroundColor: "rgba(144, 124, 255, 0.1)",
                    borderRadius: 3,
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: "500",
                      fontFamily: FontFamily.regular,
                      color: "#907CFF",
                    }}
                  >
                    at {userPlan.currency_code == "INR" ? "₹" : "$"}
                    {userPlan.description.monthly_price}/month
                  </Text>
                </View>
              </View>
            )}
          </>
        )}
        <View style={{ flex: 1 }}>
          {userPlan.description.access_desc_new ? (
            <>
              {userPlan.description.access_desc_new.map((itemElement) => {
                return (
                  <View style={[Margin.mt_1, Margin.mb_0]}>
                    <View style={[Flex.row, { alignItems: "flex-start" }]}>
                      <View style={{ minWidth: 14 }}>
                        <CustomImage
                          source={checkIcon}
                          require={true}
                          style={{
                            width: 20,
                            height: 14,
                            resizeMode: "contain",
                            marginTop: 3,
                          }}
                          webStyle={{
                            width: 20,
                            height: 14,
                            objectFit: "contain",
                            marginTop: 3,
                          }}
                        />
                      </View>
                      <View
                        style={[
                          { flexDirection: "row", flexWrap: "wrap", flex: 1 },
                        ]}
                      >
                        {Object.keys(itemElement).map((key) => {
                          if (key.includes("text")) {
                            return (
                              <Text
                                style={[
                                  subscribePlans.planDesc,
                                  helpers.isDesktop() &&
                                    subscribePlans.planDescDesktop,
                                  this.props.sidePanel &&
                                    subscribePlans.planDescPanel,
                                  Margin.ml_1,
                                  { textAlign: "left", marginBottom: 0 },
                                  darkModeOption && {
                                    color: Colors.lightWhite,
                                  },
                                ]}
                              >
                                {itemElement[key]}
                              </Text>
                            );
                          } else {
                            let linkClick;
                            if (helpers.isWeb()) {
                              linkClick = {
                                href: itemElement[key]["href"],
                              };
                            } else {
                              linkClick = {
                                onPress: () =>
                                  Linking.openURL(itemElement[key]["href"]),
                              };
                            }
                            return (
                              <TouchableOpacityLinkExternal
                                {...linkClick}
                                newTab={true}
                              >
                                <Text
                                  style={[
                                    subscribePlans.planDesc,
                                    helpers.isDesktop() &&
                                      subscribePlans.planDescDesktop,
                                    this.props.sidePanel &&
                                      subscribePlans.planDescPanel,
                                    Margin.ml_1,
                                    {
                                      textAlign: "left",
                                      marginBottom: 0,
                                      textDecorationLine: "underline",
                                    },
                                    darkModeOption && {
                                      color: Colors.lightWhite,
                                    },
                                  ]}
                                >
                                  {itemElement[key]["title"]}
                                </Text>
                              </TouchableOpacityLinkExternal>
                            );
                          }
                        })}
                      </View>
                    </View>
                  </View>
                );
              })}
            </>
          ) : (
            <>
              {userPlan.description.access_desc &&
                userPlan.description.access_desc.map((itemElement, index) => {
                  return (
                    <View style={[Margin.mt_1, Margin.mb_0]}>
                      <View style={[Flex.row, { alignItems: "flex-start" }]}>
                        <CustomImage
                          source={checkIcon}
                          require={true}
                          style={{
                            width: 20,
                            height: 14,
                            resizeMode: "contain",
                            marginTop: 3,
                          }}
                          webStyle={{
                            width: 20,
                            height: 14,
                            objectFit: "contain",
                            marginTop: 3,
                          }}
                        />
                        <Text
                          style={[
                            subscribePlans.planDesc,
                            helpers.isDesktop() &&
                              subscribePlans.planDescDesktop,
                            this.props.sidePanel &&
                              subscribePlans.planDescPanel,
                            Margin.ml_1,
                            { textAlign: "left", marginBottom: 0 },
                            darkModeOption && { color: Colors.lightWhite },
                          ]}
                        >
                          {itemElement}
                        </Text>
                      </View>
                    </View>
                  );
                })}
            </>
          )}
        </View>
        {!giftPlan ? (
          <>
            {userSubscription.plan.id != "institution-plan-inr" && (
              <View>
                {userSubscription.next_billing_at &&
                userSubscription?.next_renewal?.amount_due &&
                userSubscription.auto_collection == "on" &&
                !userSubscription.is_cancelled &&
                userSubscription.status != "expired" ? (
                  <>
                    {Platform.OS != "ios" && (
                      <View
                        style={[
                          Margin.mt_2,
                          Margin.mb_0,
                          Padding.pv_2,
                          {
                            borderColor: "#DDDDDD",
                            borderTopWidth: 1,
                            borderBottomWidth: 1,
                          },
                        ]}
                      >
                        <Text
                          style={[
                            subscribePlans.planDesc,
                            helpers.isDesktop() &&
                              subscribePlans.planDescDesktop,
                            this.props.sidePanel &&
                              subscribePlans.planDescPanel,
                            Margin.mh_3,
                            { textAlign: "left", marginBottom: 0 },
                            darkModeOption && { color: Colors.lightWhite },
                          ]}
                        >
                          Your next payment of{" "}
                          <Text style={[{ color: "#9b7bf2" }]}>
                            <Text>
                              {userPlan.currency_code == "INR" ? "₹" : "$"}
                            </Text>
                            {userSubscription.next_renewal.amount_due}
                          </Text>{" "}
                          will be charged on{" "}
                          {moment(userSubscription.next_billing_at).format(
                            "Do MMMM YYYY"
                          )}
                        </Text>
                      </View>
                    )}
                  </>
                ) : (
                  <View
                    style={[
                      Margin.mt_2,
                      Margin.mb_0,
                      Padding.pv_2,
                      {
                        borderColor: "#DDDDDD",
                        borderTopWidth: 1,
                        borderBottomWidth: 1,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        subscribePlans.planDesc,
                        helpers.isDesktop() && subscribePlans.planDescDesktop,
                        this.props.sidePanel && subscribePlans.planDescPanel,
                        Margin.mh_3,
                        { textAlign: "left", marginBottom: 0 },
                        darkModeOption && { color: Colors.lightWhite },
                      ]}
                    >
                      {(userSubscription.status == "expired"
                        ? "Your plan has expired on "
                        : "Your plan will expire on ") +
                        moment(userSubscription.next_billing_at).format(
                          "Do MMMM YYYY"
                        )}
                    </Text>
                  </View>
                )}
              </View>
            )}
          </>
        ) : (
          <>
            <View
              style={[
                Margin.mt_2,
                Margin.mb_0,
                Padding.pv_2,
                { borderColor: "#000000", borderTopWidth: 1 },
              ]}
            >
              <Text
                style={[
                  subscribePlans.planDesc,
                  helpers.isDesktop() && subscribePlans.planDescDesktop,
                  this.props.sidePanel && subscribePlans.planDescPanel,
                  { textAlign: "center", marginBottom: 0, fontSize: 16 },
                  darkModeOption && { color: Colors.lightWhite },
                ]}
              >
                Gifted by:{" "}
                <Text style={[{ fontWeight: "600" }]}>
                  {userSubscription?.sender_info?.name}
                </Text>{" "}
                on{" "}
                <Text style={[{ fontWeight: "600" }]}>
                  {moment(userSubscription.start_date).format("Do MMMM YYYY")}
                </Text>
              </Text>
            </View>
          </>
        )}
      </View>
    );
  };

  pushPurchasePlanInteractionEventToDataLayer = (subscription) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "purchase_plan_interaction",
      custom_user_id: this.state.custom_user_id || "NA",
      user_status: this.state.custom_user_id ? "logged in " : "guest",
      plan_price: subscription.price,
      plan_name: subscription.name?.toLowerCase(),
      CTA_text: this.state.userSubscription ? "upgrade Plan" : "select Plan",
      scroll_depth: helpers.getScrollDepth(),
    });
  };

  subscribeToPlan = (subscribtion) => {
    console.log("subscribtion:", subscribtion);
    this.pushPurchasePlanInteractionEventToDataLayer(subscribtion)
    try {
      console.log("try");
      window.webengage.track("Click Select Plan", {
        "Type of User": this.state.token
          ? this.state.userSubscription
            ? "Paid"
            : "Free"
          : "non-logged in",
        "Plan Name": subscribtion?.name,
        "Component Name": "SubscriptionPricingPlan",
      });
      console.log("success");
    } catch (err) {}

    this.props.showSubscriptionPlan(
      subscribtion?.tmc_group == "credit" ? "credits" : subscribtion.id,
      subscribtion,
      this.state.country
    );
  };

  renewPlan = () => {
    this.props.showSubscriptionPlan("renew-plan", false, this.state.country);
  };

  renewTeamPlan = () => {
    this.props.showSubscriptionPlan(
      "renew-team-plan",
      false,
      this.state.country
    );
  };

  cancelPlan = () => {
    if (this.props.sidePanel) {
      this.props.navigateScreen("accounts/cancel-subscription", {
        plan:
          this.state.userSubscription &&
          this.state.userSubscription.is_cancelled == false
            ? true
            : false,
      });
    } else {
      if (helpers.isWeb()) {
        let pushProp = JSON.parse(JSON.stringify(window.location));
        pushProp.hash = "accounts/cancel-subscription";
        this.props.navigateScreen(pushProp);
      } else {
        this.props.navigation.navigate("accounts/cancel-subscription");
      }
    }
  };

  cancelTeamPlan = () => {
    if (this.props.sidePanel) {
      this.props.navigateScreen("accounts/cancel-team-subscription", {
        plan:
          this.state.teamSubscription &&
          this.state.teamSubscription.is_cancelled == false &&
          this.state.teamSubscription.is_team_owner
            ? true
            : false,
      });
    } else {
      if (helpers.isWeb()) {
        let pushProp = JSON.parse(JSON.stringify(window.location));
        pushProp.hash = "accounts/cancel-team-subscription";
        this.props.navigateScreen(pushProp);
      } else {
        this.props.navigation.navigate("accounts/cancel-team-subscription");
      }
    }
  };

  renderPageContent = () => {
    const { plansData, userSubscription, teamSubscription, creditPlan } =
      this.state;
    const {
      homepage,
      subscriptionPage = false,
      singlePost,
      hideTeam,
      darkMode,
      sidePanel,
      student,
      isAdmin,
      referenceId,
      showTeamSection,
      showCreditPlan,
      showSignIn,
      showExploreMore,
      quaterlySubscription,
    } = this.props;
    let darkModeOption = sidePanel
      ? helpers.isDarkMode(this.props?.app?.DarkMode)
      : darkMode;
    let individualPlans = plansData.individual
      ? JSON.parse(JSON.stringify(plansData.individual))
      : false;
    let currentTeamPlan = {
      title:
        teamSubscription.plan?.tmc_group == "team_credit"
          ? "Corporate - Privilege Credits"
          : "Corporate - Seat based plan",
      // footerTextBefore: 'Want to cancel your subscription?',
      footerButtonText:
        teamSubscription.is_team_owner &&
        teamSubscription.is_cancelled == false &&
        teamSubscription.can_cancel &&
        isAdmin != true
          ? "Cancel Subscription"
          : false,
      buttonText: false,
      footerTextAfter: teamSubscription.is_cancelled ? (
        <Text>
          This plan was cancelled and will continue to be active till{" "}
          <Text>
            {moment(teamSubscription.end_date).format("Do MMMM YYYY")}
          </Text>
          .
        </Text>
      ) : teamSubscription.is_team_owner ? (
        teamSubscription.is_team_member ? (
          false
        ) : (
          <Text>
            You are not a member of this plan but you can manage this plan for
            your organization.
          </Text>
        )
      ) : (
        <Text>
          Only the owner can update, cancel or remove you from this plan.
        </Text>
      ),
    };
    let currentPlan = {
      title: userSubscription.plan?.invoice_name,
      // footerTextBefore: 'Want to cancel your subscription?',
      footerButtonText:
        userSubscription.is_cancelled == false &&
        userSubscription.can_cancel &&
        isAdmin != true
          ? "Cancel Subscription"
          : false,
      buttonText: false,
      footerTextAfter:
        userSubscription.is_cancelled &&
        userSubscription.plan?.tmc_group != "gift_plan" ? (
          <Text>
            This plan was cancelled and will continue to be active till{" "}
            <Text>
              {moment(userSubscription.end_date).format("Do MMMM YYYY")}
            </Text>
            .
          </Text>
        ) : (
          false
        ),
    };
    let giftPlan =
      userSubscription.plan?.tmc_group == "gift_plan" ? true : false;
    let checkDesktop = this.props.sidePanel
      ? false
      : helpers.isDesktop()
      ? true
      : false;
    let multiView =
      individualPlans &&
      (((userSubscription || teamSubscription) && individualPlans.length > 1) ||
        (userSubscription && teamSubscription && individualPlans.length > 0))
        ? true
        : false;

    let showStudentSection = false;
    if (showCreditPlan && creditPlan) {
      showStudentSection = true;
    }
    if (individualPlans) {
      // individualPlans.push(creditPlan);
      if (plansData && plansData["free"]) {
        individualPlans = [
          ...plansData["free"],
          // ...plansData["credit"],
          ...individualPlans,
        ];
        console.log("individualPlansfree:", individualPlans);
      } else {
        individualPlans.push(creditPlan);
      }
    } else {
      individualPlans = [creditPlan];
    }
    if (sidePanel) {
      showStudentSection = false;
    }
    let webView = helpers.isWeb();

    let btnClickStudents, btnClickTeams, btnClickGift;

    if (helpers.isWeb()) {
      btnClickStudents = {
        to: "/student-subscription",
      };
      btnClickTeams = {
        to: "/team-subscription",
      };
      btnClickGift = {
        to: "/gifting",
      };
    } else {
      btnClickStudents = {
        onPress: () => this.props.navigateScreen("/student-subscription"),
      };
      btnClickTeams = {
        onPress: () => this.props.navigateScreen("/team-subscription"),
      };
      btnClickGift = {
        onPress: () => this.props.navigateScreen("/gifting"),
      };
    }


    const showActivePlan = () => {
      if (!userSubscription) return <></>
      return <View style={{ display: 'flex', alignItems: 'center', width: '80%', rowGap: 10, backgroundColor: '#FFF3E3', borderColor: '#E7D6C9', borderWidth: 1, paddingBottom: 20 }}>
        <View style={{ marginTop: 20 }}>
          <Text style={{ color: '#051462', fontWeight: 600, fontSize: 24, textAlign: 'center' }}>{userSubscription?.plan?.description?.display_name?.toUpperCase() || "Friends of TMC" }</Text>
        </View>
        <View>
        {userSubscription?.plan?.price > 0 && (
          <Text style={{ color: '#051462', fontSize: 38, fontWeight: 800 }}>
            {userSubscription?.plan?.currency_code === 'INR' 
              ? `₹${userSubscription?.plan?.price}` 
              : `$${userSubscription?.plan?.price}`}
          </Text>
        )}
        </View>
        <View style={{ width: '80%', display: 'flex', flexDirection: 'column', columnGap: 10, justifyContent: 'center', rowGap: 10, marginTop: userSubscription?.plan?.price > 0 ? 30:0 }}>
          {userSubscription?.plan?.description?.access_desc?.map((desc) => <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10 }}>
            <CustomImage
              source={greenTick}
              style={{ width: 10, height: 10, objectFit: 'contain' }}
              webStyle={{ width: 15, height: 15, objectFit: 'contain' }}
              className={'green-tick-icon'}
              altText={name}
            />
            <Text style={{ color: '#051462', fontSize: 16, lineHeight: '150%' }}>{desc}</Text>
          </View>)}
        </View>
        <TouchableOpacity onPress={() => {
        }} style={{ borderWidth: 1, borderColor: '#051462', width: '100%', padding: 8, width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#051462' }}>
          <Text style={{ fontSize: 14, fontWeight: 700, color: 'white' }}>{"YOUR CURRENT PLAN"}</Text>
        </TouchableOpacity>
      </View>
    }

    let userHasGiftPlan = userSubscription?.plan?.tmc_group == "gift_plan";

    // Code changes to support the $3 plan
    individualPlans = individualPlans.map((plan) => {
      if ( !(userSubscription && userSubscription.plan?.tmc_group != "free") && plan?.trial?.has_trial) {
        return {
          ...plan,
          price:
            plan.trial?.trial_price +
            " for " +
            plan.trial?.trial_period +
            " days",
          description: {
            ...plan.description,
            access_desc: plan.description.trial_desc,
          },
          priceHelperText: "New Users Only",
        };
      }
      return plan;
    });

    return (
      <>
        <View
          style={[
            Flex.column,
            Flex.alignCenter,
            subscriptionPage && {
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            },
            subscriptionPage && webView && { alignItems: "unset" },
            helpers.isDesktop() &&
              subscriptionPage &&
              userSubscription?.plan?.id === "quarterly-plan-in" && {
                position: "relative",
                left: -80,
              },
          ]}
        >
          {Platform.OS == "ios" &&
            ((!userSubscription && !teamSubscription) ||
              (userSubscription &&
                userSubscription.plan.id === "quarterly-plan-in") ||
              (userSubscription && userSubscription.status == "expired") ||
              (teamSubscription && teamSubscription.status == "expired")) && (
              <View
                style={[
                  {
                    backgroundColor: darkMode
                      ? Colors.darkBlackColor1
                      : "#FFFAEB",
                    paddingHorizontal: 15,
                    paddingVertical: 15,
                    marginTop: 20,
                  },
                  !userSubscription &&
                    !teamSubscription && { marginBottom: 20 },
                  (userSubscription || teamSubscription) && {
                    marginBottom: -15,
                  },
                ]}
              >
                <Text
                  style={{
                    fontSize: 16,
                    lineHeight: 27,
                    letterSpacing: 0.3,
                    color: darkMode ? Colors.lightWhite : "#2D2D2D",
                    fontFamily: FontFamily.merriweather,
                  }}
                >
                  You can’t upgrade in the app. We know it’s a hassle.
                </Text>
              </View>
            )}
          {teamSubscription && (
            <>
              <View
                style={[
                  { maxWidth: checkDesktop ? "33.33%" : "100%", width: "100%" },
                  subscriptionPage && { marginBottom: 20 },
                ]}
              >
                <View style={[subscriptionPage && { flex: 1 }]}>
                  <View
                    style={[
                      Margin.mt_2,
                      !checkDesktop && Margin.mt_5,
                      {
                        backgroundColor:
                          teamSubscription.status == "expired" ||
                          teamSubscription.is_cancelled
                            ? "#4C4C4C"
                            : "#3DAEFB",
                        marginHorizontal: 20,
                      },
                      subscriptionPage && { marginTop: 0 },
                    ]}
                  >
                    <Text
                      style={[
                        Helpers.textUppercase,
                        Helpers.textCenter,
                        Margin.mt_1,
                        {
                          fontSize: 12,
                          fontFamily: FontFamily.regular,
                          fontWeight: FontWeight.semiBold,
                          marginTop: 12,
                          marginBottom: 12,
                          color: "white",
                        },
                        !helpers.isWeb() && { fontFamily: FontFamily.bold },
                      ]}
                    >
                      {(teamSubscription.status == "expired"
                        ? "Expired"
                        : teamSubscription.is_cancelled
                        ? "Cancelled"
                        : "Active") +
                        " - " +
                        (teamSubscription.is_team_owner ? "Admin" : "Member")}
                    </Text>
                    {(teamSubscription.auto_collection == "on" &&
                      !teamSubscription.is_cancelled &&
                      teamSubscription.next_billing_at) ||
                    teamSubscription.end_date ? (
                      <Text
                        style={[
                          Helpers.textCenter,
                          {
                            fontSize: 14,
                            lineHeight: 21,
                            fontFamily: FontFamily.regular,
                            letterSpacing: 0.3,
                            marginBottom: 10,
                            color: "rgba(255,255,255,0.85)",
                            fontWeight: "400",
                          },
                        ]}
                      >
                        {(teamSubscription.auto_collection == "on" &&
                        !teamSubscription.is_cancelled
                          ? "Renews on: "
                          : teamSubscription.status == "expired"
                          ? ""
                          : "Expires on: ") +
                          moment(
                            teamSubscription.auto_collection == "on" &&
                              !teamSubscription.is_cancelled
                              ? teamSubscription.next_billing_at
                              : teamSubscription.end_date
                          ).format("Do MMMM YYYY") +
                          (teamSubscription.advance_renewal_paid
                            ? " (payment done)"
                            : "")}
                      </Text>
                    ) : (
                      <></>
                    )}
                  </View>
                  <View
                    style={[
                      Padding.ph_2,
                      Padding.pb_2,
                      subscriptionPage && { flex: 1 },
                    ]}
                  >
                    <ActionBox
                      data={currentTeamPlan}
                      renderDesc={() => this.renderCurrentTeamPlan()}
                      footerClick={() => {
                        if (isAdmin != true) {
                          this.cancelTeamPlan();
                        }
                      }}
                      onButtonClick={() => {
                        if (isAdmin != true) {
                          this.renewTeamPlan();
                        }
                      }}
                      singleLine={true}
                      boxColor={
                        teamSubscription.status == "expired" ||
                        teamSubscription.is_cancelled
                          ? "#4C4C4C"
                          : "#3AACFF"
                      }
                      subscriptionPage={subscriptionPage}
                      darkMode={darkModeOption}
                      disableActions={isAdmin ? true : false}
                    />
                  </View>
                </View>
              </View>
              {!checkDesktop && userSubscription ? (
                <View
                  style={[
                    Flex.alignCenter,
                    { marginBottom: 40, width: "100%" },
                  ]}
                >
                  <View
                    style={[
                      Padding.ph_2,
                      Helpers.titleBgWrap,
                      darkModeOption && {
                        backgroundColor: Colors.darkBlackColorBg,
                      },
                    ]}
                  >
                    <View
                      style={[
                        Helpers.borderBottom,
                        darkModeOption && {
                          borderBottomColor: Colors.lineColor,
                        },
                      ]}
                    >
                      <Text
                        style={[
                          Helpers.textCenter,
                          Typo.h3_R,
                          helpers.isDesktop() && Typo.h3_RDesktop,
                          Margin.mb_0,
                          { fontSize: 20 },
                          { fontFamily: FontFamily.abrilFatface },
                        ]}
                      >
                        Other Active Plans
                      </Text>
                    </View>
                  </View>
                  <View
                    style={[
                      Helpers.titleLine,
                      {
                        backgroundColor: darkModeOption
                          ? Colors.lineColor
                          : "rgba(0, 0, 0, 0.6)",
                      },
                    ]}
                  ></View>
                </View>
              ) : (
                <>
                  {!checkDesktop && teamSubscription.is_team_member ? (
                    <Text
                      style={[
                        Padding.ph_2,
                        {
                          color: "#6A6A6A",
                          fontFamily: FontFamily.regular,
                          textAlign: "center",
                          fontWeight: "400",
                          marginBottom: 8,
                        },
                        darkModeOption && { color: Colors.lightWhite },
                      ]}
                    >
                      *Individual plans are not available since you are already
                      a member of a team plan.
                    </Text>
                  ) : (
                    <>
                      {!this.props.hidePagebreak && (
                        <View style={[Margin.mb_2]}>
                          <PageBreak darkMode={darkModeOption} />
                        </View>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {userSubscription &&
            (userSubscription?.plan?.tmc_group && ["gift_plan", "referral"].includes(userSubscription.plan.tmc_group)) && (
              <>
                <View
                  style={[
                    {
                      maxWidth: checkDesktop ? "33.33%" : "100%",
                      width: "100%",
                    },
                    subscriptionPage && { marginBottom: 20 },
                    helpers.isDesktop() &&
                      userSubscription?.plan?.id === "quarterly-plan-in" && {
                        transform: [{ scale: 0.92 }],
                      },
                    helpers.isDesktop() &&
                      subscriptionPage &&
                      userSubscription?.plan?.id === "quarterly-plan-in" && {
                        marginRight: 150,
                      },
                  ]}
                >
                  <View
                    style={[subscriptionPage && helpers.isWeb() && { flex: 1 }]}
                  >
                    <View
                      style={[
                        Margin.mt_2,
                        !checkDesktop && Margin.mt_5,
                        {
                          backgroundColor:
                            userSubscription.plan?.tmc_group == "gift_plan"
                              ? "#485BFF"
                              : userSubscription.status == "expired" ||
                                userSubscription.is_cancelled
                              ? "#FC876D"
                              : "#3DAEFB",
                          marginHorizontal: 20,
                        },
                        subscriptionPage && { marginTop: 0 },
                      ]}
                    >
                      <Text
                        style={[
                          Helpers.textUppercase,
                          Helpers.textCenter,
                          Margin.mt_1,
                          {
                            fontSize: 12,
                            fontFamily: FontFamily.regular,
                            fontWeight: FontWeight.semiBold,
                            marginBottom: 8,
                            color: "white",
                          },
                          !helpers.isWeb() && { fontFamily: FontFamily.bold },
                        ]}
                      >
                        {userSubscription.plan?.tmc_group == "gift_plan"
                          ? "Current plan"
                          : userSubscription.status == "expired"
                          ? "Expired"
                          : userSubscription.is_cancelled
                          ? "Plan Cancelled"
                          : "Current plan"}
                      </Text>
                      {(userSubscription.auto_collection == "on" &&
                        !userSubscription.is_cancelled &&
                        userSubscription.next_billing_at) ||
                      (userSubscription.end_date &&
                        userSubscription.plan.id != "institution-plan-inr") ? (
                        <Text
                          style={[
                            Helpers.textCenter,
                            {
                              fontSize: 14,
                              lineHeight: 21,
                              fontFamily: FontFamily.regular,
                              letterSpacing: 0.3,
                              marginBottom: 10,
                              color: "rgba(255,255,255,0.85)",
                              fontWeight: "400",
                            },
                          ]}
                        >
                          {(userSubscription.auto_collection == "on" &&
                          !userSubscription.is_cancelled
                            ? "Renews on: "
                            : userSubscription.status == "expired"
                            ? ""
                            : "Expires on: ") +
                            moment(
                              userSubscription.auto_collection == "on" &&
                                !userSubscription.is_cancelled
                                ? userSubscription.next_billing_at
                                : userSubscription.end_date
                            ).format("Do MMMM YYYY") +
                            (userSubscription.advance_renewal_paid
                              ? " (payment done)"
                              : "")}
                        </Text>
                      ) : (
                        <Text
                          style={[
                            Helpers.textCenter,
                            {
                              fontSize: 14,
                              lineHeight: 21,
                              fontFamily: FontFamily.regular,
                              letterSpacing: 0.3,
                              marginBottom: 10,
                              color: "rgba(255,255,255,0.85)",
                              fontWeight: "400",
                            },
                          ]}
                        >
                          {" "}
                        </Text>
                      )}
                    </View>
                    <View
                      style={[
                        Padding.ph_2,
                        Padding.pb_2,
                        subscriptionPage && { flex: 1 },
                      ]}
                    >
                      <ActionBox
                        data={currentPlan}
                        renderDesc={() => this.renderCurrentPlan(giftPlan)}
                        footerClick={() => {
                          if (isAdmin != true) {
                            this.cancelPlan();
                          }
                        }}
                        onButtonClick={() => {
                          if (isAdmin != true) {
                            this.renewPlan();
                          }
                        }}
                        singleLine={true}
                        boxColor={
                          userSubscription.plan?.tmc_group == "gift_plan"
                            ? "#485BFF"
                            : userSubscription.status == "expired" ||
                              userSubscription.is_cancelled
                            ? "#FC876D"
                            : "#3AACFF"
                        }
                        subscriptionPage={subscriptionPage}
                        darkMode={darkModeOption}
                        disableActions={isAdmin ? true : false}
                        giftPlan={giftPlan}
                      />
                    </View>
                  </View>
                </View>
                {!this.props.hidePagebreak && (
                  <View style={[Margin.mb_2]}>
                    <PageBreak darkMode={darkModeOption} />
                  </View>
                )}
              </>
            )}
            {/* BP-1 */}
          {individualPlans &&
            individualPlans.length > 0 &&
            !userHasGiftPlan &&
            !teamSubscription && !(userSubscription?.plan?.tmc_group === 'friends-of-tmc') && (
              <View
                style={[
                  webView && [Margin.mt_2, Margin.mb_2],
                  { width: "100%" },
                  homepage && {
                    backgroundColor: darkModeOption
                      ? Colors.darkBlackColor
                      : "#ffffff",
                    margin: 0,
                  },
                  subscriptionPage && {
                    maxWidth: helpers.isDesktop() ? "29%" : "100%",
                  },
                  checkDesktop && multiView && { maxWidth: "100%", flex: 1 },

                  userSubscription?.plan?.is_popular &&
                    multiView && {
                      maxWidth: helpers.isDesktop() ? "29%" : "100%",
                    },
                ]}
              >
                <View
                  style={[
                    Padding.ph_2,
                    subscriptionPage && { flex: 1 },
                    homepage &&
                      !helpers.isDesktop() && {
                        paddingLeft: 0,
                        paddingRight: 0,
                      },
                    singlePost &&
                      !helpers.isDesktop() && {
                        paddingLeft: 0,
                        paddingRight: 0,
                      },
                  ]}
                >
                  {/*<Text style={[Typo.h3, Helpers.textUppercase, Helpers.textCenter, Margin.mt_2, Margin.mb_3]}>Looking to <Text style={{ color: Colors.primary }}>Upgrade your plan?</Text> Choose from Our subscription plans</Text>*/}
                  {/*<Text style={[Typo.h3, Helpers.textUppercase, Helpers.textCenter, Margin.mt_3, Margin.mb_3]}>INDIVIDUAL SUBSCRIPTION</Text> */}

                  {userSubscription?.plan?.id !== 'institution-plan-inr' && (<SubscriptionPlans
                    sidePanel={this.props.sidePanel}
                    subscribeToPlan={(subscribtion) =>
                      this.subscribeToPlan(subscribtion)
                    }
                    title={""}
                    data={this.state.userSubscription?.plan?.tmc_group === 'exclusive' ? [] : individualPlans}
                    btnTitle={userSubscription ? "Upgrade Plan" : "Select Plan"}
                    subscriptionPage={subscriptionPage}
                    multiplePlans={checkDesktop && multiView ? true : false}
                    userSubscription={userSubscription}
                    userSubscriptionExpired={this.state.userSubscriptionExpired}
                    navigateToTeamSubscription={() =>
                      this.navigateToTeamSubscription()
                    }
                    navigateScreen={this.props.navigateScreen}
                    darkMode={darkModeOption}
                    quaterlySubscription={quaterlySubscription}
                    auth={this.props.auth}
                    country={this.state.country}
                    setCheckoutLoaderState={(loaderState) =>
                      this.props.setCheckoutLoaderState(loaderState)
                    }
                    getCheckoutLoaderState={this.props.getCheckoutLoaderState}
                    custom_user_id={this.state.custom_user_id}
                  />)}
                </View>
              </View>
            )}
            {
              (userSubscription?.plan?.tmc_group === 'friends-of-tmc' ||  userSubscription?.plan?.tmc_group === 'exclusive' ||  userSubscription?.plan?.id === 'institution-plan-inr') && (
                <View style={{display: 'flex', justifyContent: 'center', width: '100%', alignSelf: 'center', alignItems: 'center'}}>
                  {showActivePlan()}
                </View>
              )
            }
        </View>
        {checkDesktop && teamSubscription.is_team_member && (
          <View style={Flex.alignCenter}>
            {userSubscription ? (
              <></>
            ) : (
              <Text
                style={[
                  Padding.ph_2,
                  {
                    color: "#000000",
                    fontFamily: FontFamily.regular,
                    textAlign: "center",
                    fontWeight: "400",
                    fontSize: 16,
                  },
                  darkModeOption && { color: Colors.lightWhite },
                ]}
              >
                Individual plans are not available since you are already a
                member of a team plan.
              </Text>
            )}
          </View>
        )}
        {/* {showStudentSection && Platform.OS != 'ios' &&
                    <View style={[{ paddingHorizontal: 20, marginBottom: 12 }, helpers.isDesktop() && Margin.mt_1, homepage && { backgroundColor: darkModeOption ? Colors.darkBlackColor : '#fbf5ff' }]}>
                        <Text style={{ fontFamily: FontFamily.regular, fontWeight: '400', fontSize: helpers.isDesktop() ? 22 : 18, lineHeight: helpers.isDesktop() ? 22 : 20, color: darkModeOption ? Colors.lineColor : 'rgba(0, 0, 0, 1)', textAlign: 'center', marginBottom: 5 }}>
                            <Text style={{ fontWeight: helpers.isWeb() ? '600' : '700' }}>
                                TMC for students
                            </Text>
                        </Text>
                        <Text style={{ fontFamily: FontFamily.regular, fontWeight: '400', fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 22 : 20, color: darkModeOption ? Colors.lineColor : 'rgba(0, 0, 0, 1)', textAlign: 'center', paddingHorizontal: helpers.isDesktop() ? 180 : 10 }}>
                            <TouchableOpacityLink {...btnClickStudents} >
                                <Text style={[{ fontFamily: FontFamily.regular, fontWeight: '400', fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 22 : 20, textDecorationLine: 'underline', color: darkModeOption ? Colors.lineColor : Colors.brandColor1, textAlign: 'center' }, !helpers.isWeb() && { top: 3, position: 'relative' }]}>
                                    Click here
                                </Text>
                            </TouchableOpacityLink> to Get 50% discount on our annual subscription and access to our exclusive student community on Telegram
                        </Text>
                        {showSignIn &&
                            <View style={[Padding.ph_2, Flex.alignCenter, Flex.row, { justifyContent: 'center', paddingBottom: 20, paddingTop: 20 }]}>
                                <Text style={[{ fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 27 : 22, fontFamily: FontFamily.regular, fontWeight: helpers.isWeb() ? '600' : '700', marginBottom: 0, color: '#000' }, darkMode && { color: 'rgba(255, 255, 255, 0.89)' }]}>Already a member?</Text>
                                <Button
                                    title={'Sign in'}
                                    rootStyle={{
                                        btnWrapper: [{ marginLeft: 5 }],
                                        btnText: [{ fontFamily: FontFamily.regular, fontSize: helpers.isDesktop() ? 18 : 14, lineHeight: helpers.isDesktop() ? 20 : 18, textDecorationLine: 'underline', color: darkMode ? Colors.lineColor : Colors.brandColor1 }]
                                    }}
                                    onClick={() => this.props.goToLogin()}
                                />
                            </View>
                        }
                    </View>
                } */}

        {/* {showTeamSection && Platform.OS != 'ios' &&
                    <View style={[{ paddingHorizontal: 20 }, helpers.isDesktop() && Margin.mt_1, homepage && { backgroundColor: darkModeOption ? Colors.darkBlackColor : '#fbf5ff' }]}>
                        <Text style={{ fontFamily: FontFamily.regular, fontWeight: '400', fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 22 : 20, color: darkModeOption ? Colors.lineColor : 'rgba(0, 0, 0, 1)', textAlign: 'center' }}><Text style={{ fontWeight: helpers.isWeb() ? '600' : '700' }}>Are you’ll a team?</Text> <TouchableOpacityLink {...btnClickTeams} >
                            <Text style={[{ fontFamily: FontFamily.regular, fontWeight: '400', fontSize: helpers.isDesktop() ? 20 : 16, lineHeight: helpers.isDesktop() ? 22 : 20, textDecorationLine: 'underline', color: darkModeOption ? Colors.lineColor : Colors.brandColor1, textAlign: 'center' }, !helpers.isWeb() && { top: 3, position: 'relative' }]}>Click here</Text>
                        </TouchableOpacityLink> to know about our Corporate subscription plan.</Text>
                    </View>
                } */}

        {userSubscription == false && teamSubscription == false && isAdmin && (
          <View
            style={[
              { paddingHorizontal: 20 },
              helpers.isDesktop() && Margin.mt_1,
              homepage && {
                backgroundColor: darkModeOption
                  ? Colors.darkBlackColor
                  : "#fbf5ff",
              },
            ]}
          >
            <Text
              style={{
                fontFamily: FontFamily.regular,
                fontSize: helpers.isDesktop() ? 20 : 16,
                lineHeight: helpers.isDesktop() ? 22 : 20,
                color: darkModeOption ? Colors.lineColor : "rgba(0, 0, 0, 1)",
                textAlign: "center",
              }}
            >
              User does not have a plan.
            </Text>
          </View>
        )}

        {showExploreMore && (
          <View
            style={{
              flexDirection: helpers.isDesktop() ? "row" : "column",
              marginTop: 30,
              marginBottom: 70,
              maxWidth: 900,
              marginLeft: "auto",
              marginRight: "auto",
              alignItems: helpers.isDesktop() ? "center" : "flex-start",
              justifyContent: helpers.isDesktop() ? "center" : "flex-start",
              paddingHorizontal: singlePost ? 0 : 20,
            }}
          >
            <View
              style={[
                {
                  paddingRight: helpers.isDesktop() ? 40 : 0,
                  marginBottom: helpers.isDesktop() ? 0 : 40,
                },
                !helpers.isDesktop() && {
                  alignItems: "center",
                  marginRight: "auto",
                  marginLeft: "auto",
                },
              ]}
            >
              <Text
                style={[
                  {
                    fontSize: 18,
                    fontFamily: FontFamily.regular,
                    fontWeight: "600",
                    color: darkMode ? Colors.lightWhite : "rgba(0,0,0,0.7)",
                    letterSpacing: 0.5,
                    textTransform: "uppercase",
                    marginBottom: 5,
                  },
                  !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                ]}
              >
                Explore more
              </Text>
              <Text
                style={[
                  {
                    fontSize: 18,
                    fontFamily: FontFamily.regular,
                    fontWeight: "600",
                    color: darkMode ? Colors.lightWhite : "rgba(0,0,0,0.7)",
                    letterSpacing: 0.5,
                    textTransform: "uppercase",
                    marginBottom: 5,
                  },
                  !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                ]}
              >
                subscription
              </Text>
              <Text
                style={[
                  {
                    fontSize: 18,
                    fontFamily: FontFamily.regular,
                    fontWeight: "600",
                    color: darkMode ? Colors.lightWhite : "rgba(0,0,0,0.7)",
                    letterSpacing: 0.5,
                    textTransform: "uppercase",
                  },
                  !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                ]}
              >
                options
              </Text>
            </View>
            <View
              style={{
                borderLeftWidth: helpers.isDesktop() ? 1 : 0,
                borderLeftColor: "rgba(0, 0, 0, 0.3)",
                paddingLeft: helpers.isDesktop() ? 30 : 0,
              }}
            >
              <View style={{ flexDirection: "row", marginBottom: 20 }}>
                <TouchableOpacityLink {...btnClickStudents}>
                  <View style={{ flexDirection: "row" }}>
                    <CustomImage
                      source={studenOrangeIcon}
                      require={true}
                      style={{
                        width: 30,
                        height: 30,
                        resizeMode: "contain",
                        marginRight: 12,
                      }}
                      webStyle={{
                        width: 30,
                        height: 30,
                        objectFit: "contain",
                        marginRight: 20,
                        minWidth: 30,
                      }}
                    />
                    <View style={{ width: "90%" }}>
                      <Text
                        style={{
                          fontSize: helpers.isDesktop() ? 20 : 16,
                          fontFamily: FontFamily.regular,
                          lineHeight: helpers.isDesktop() ? 32 : 22,
                          fontWeight: "500",
                          color: darkMode ? Colors.lightWhite : "#000000",
                        }}
                      >
                        50% off for students. Reach out to us for campus
                        subscriptions
                      </Text>
                    </View>
                  </View>
                </TouchableOpacityLink>
              </View>
              <View style={{ flexDirection: "row", marginBottom: 20 }}>
                <TouchableOpacityLink {...btnClickGift}>
                  <View style={{ flexDirection: "row" }}>
                    <CustomImage
                      source={giftOrangeIcon}
                      require={true}
                      style={{
                        width: 30,
                        height: 30,
                        resizeMode: "contain",
                        marginRight: 12,
                      }}
                      webStyle={{
                        width: 30,
                        height: 30,
                        objectFit: "contain",
                        marginRight: 20,
                        minWidth: 30,
                      }}
                    />
                    <View style={{ width: "90%" }}>
                      <Text
                        style={{
                          fontSize: helpers.isDesktop() ? 20 : 16,
                          fontFamily: FontFamily.regular,
                          lineHeight: helpers.isDesktop() ? 32 : 22,
                          fontWeight: "500",
                          color: darkMode ? Colors.lightWhite : "#000000",
                        }}
                      >
                        Give gift access to TMC
                      </Text>
                    </View>
                  </View>
                </TouchableOpacityLink>
              </View>
              <View style={{ flexDirection: "row", marginBottom: 0 }}>
                <TouchableOpacityLink {...btnClickTeams}>
                  <View style={{ flexDirection: "row" }}>
                    <CustomImage
                      source={accesOrangeIcon}
                      require={true}
                      style={{
                        width: 30,
                        height: 30,
                        resizeMode: "contain",
                        marginRight: 12,
                      }}
                      webStyle={{
                        width: 30,
                        height: 30,
                        objectFit: "contain",
                        marginRight: 20,
                        minWidth: 30,
                      }}
                    />
                    <View style={{ width: "90%" }}>
                      <Text
                        style={{
                          fontSize: helpers.isDesktop() ? 20 : 16,
                          fontFamily: FontFamily.regular,
                          lineHeight: helpers.isDesktop() ? 32 : 22,
                          fontWeight: "500",
                          color: darkMode ? Colors.lightWhite : "#000000",
                        }}
                      >
                        Get access for your entire team or organisation{" "}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacityLink>
              </View>
            </View>
          </View>
        )}

        {!helpers.isWeb() && this.props.sidePanel && (
          <View style={{ height: 60 }}></View>
        )}
      </>
    );
  };

  renderCancelSubscriptionModal = () => {
    let { darkMode, sidePanel } = this.props;
    let darkModeOption = sidePanel
      ? helpers.isDarkMode(this.props?.app?.DarkMode)
      : darkMode;
    return (
      <View>
        <Text style={[Typo.h3, Helpers.textUppercase, Helpers.textCenter]}>
          CANCEL SUBSCRIPTION
        </Text>
        <PageBreak style={{ marginTop: 6 }} size={"small"} />
        <View style={[Margin.mt_2, Margin.mb_3]}>
          <Text style={[subscribePlans.cancelDesc, Helpers.textCenter]}>
            Are you sure you want to cancel your subscription?
          </Text>
        </View>
        <View style={[Margin.mb_4]}>
          <Text style={[subscribePlans.currentPlanEven, Helpers.textCenter]}>
            Current Plan:{" "}
            <Text style={[subscribePlans.currentPlanTypeEven]}>Quarterly</Text>
          </Text>
          <Text
            style={[
              subscribePlans.PlanPriceOdd,
              { color: Colors.brandColor1 },
              Helpers.textCenter,
              { marginVertical: 5 },
            ]}
          >
            ₹1199
          </Text>
          <Text
            style={[
              subscribePlans.infoText,
              Helpers.textItalic,
              Helpers.textCenter,
              { opacity: 0.7 },
            ]}
          >
            Next payment date: 1 January 2021
          </Text>
        </View>
        <View>
          <View style={[Flex.row, Margin.mb_1]}>
            <View style={[Helpers.liDot]} />
            <Text
              style={[
                Helpers.liText,
                darkModeOption && { color: Colors.lightWhite },
              ]}
            >
              It will take effect at the end of your current billing period on{" "}
              <Text style={[Helpers.textBold]}>31 December 2020</Text>
            </Text>
          </View>
          <View style={[Flex.row]}>
            <View style={[Helpers.liDot]} />
            <Text
              style={[
                Helpers.liText,
                darkModeOption && { color: Colors.lightWhite },
              ]}
            >
              You can always come back and subscribe to us again
            </Text>
          </View>
        </View>
        <View style={[Margin.mt_4]}>
          <ButtonGradient
            title={"CONFIRM"}
            rootStyle={{
              btnWrapper: button.primaryGradient,
              btnText: button.primaryGradientText,
            }}
            darkMode={darkModeOption}
          />
        </View>
        <View style={[Margin.mt_1]}>
          <Text
            style={[
              Helpers.textCenter,
              subscribePlans.footertext,
              darkModeOption && { color: Colors.lightWhite },
            ]}
          >
            Before you leave, maybe we can help you: Contact us on 180 - 1800
            for support
          </Text>
        </View>
      </View>
    );
  };

  renderUpgradeModalContent = () => {
    return (
      <View>
        <Text style={[Typo.h3, Helpers.textUppercase, Helpers.textCenter]}>
          UPGRADE PLAN
        </Text>
        <PageBreak size={"small"} />
        <View style={[Margin.mt_3]}>
          <Text style={[subscribePlans.currentPlan, Helpers.textCenter]}>
            Current Plan:{" "}
            <Text style={[subscribePlans.currentPlanType]}>Quarterly</Text>
          </Text>
          <Text style={[subscribePlans.PlanDescOdd, Helpers.textCenter]}>
            10 long reads + newsletters + complete access to TMC 1 newsletter &
            daily stories
          </Text>
          <Text style={[subscribePlans.PlanPriceOdd, Helpers.textCenter]}>
            <Text style={[subscribePlans.PlanPriceSymbolOdd]}>₹</Text> 1199
          </Text>
        </View>
        <View style={[subscribePlans.seprator, Margin.mt_2, Margin.mb_2]} />
        <View>
          <Text style={[subscribePlans.currentPlanEven, Helpers.textCenter]}>
            Current Plan:{" "}
            <Text style={[subscribePlans.currentPlanTypeEven]}>Quarterly</Text>
          </Text>
          <Text style={[subscribePlans.PlanDescEven, Helpers.textCenter]}>
            10 long reads + newsletters + complete access to TMC 1 newsletter &
            daily stories
          </Text>
          <Text style={[subscribePlans.PlanPriceEven, Helpers.textCenter]}>
            <Text style={[subscribePlans.PlanPriceSymbolEven]}>₹</Text> 1199
          </Text>
        </View>
        <View style={[Margin.mt_4]}>
          <ButtonGradient
            title={"CONFIRM CHANGE"}
            rootStyle={{
              btnWrapper: button.primaryGradient,
              btnText: button.primaryGradientText,
            }}
            darkMode={darkMode}
          />
        </View>
      </View>
    );
  };

  renderWebPage = () => {
    const { sidePanel } = this.props;
    let checkDesktop = sidePanel ? false : helpers.isDesktop ? true : false;
    return (
      <>
        {/* { checkDesktop && <PageBreak /> } */}
        {/* {this.renderPageContent()} */}
        {this.state.teamSubscription ||
        (this.state.plansData && this.state.plansData["individual"]?.length)
          ? this.renderPageContent()
          : null}
      </>
    );
  };

  renderAppPage = () => {
    return (
      <>
        <ScrollView style={{ flex: 1 }}>{this.renderPageContent()}</ScrollView>
      </>
    );
  };

  render() {
    const { pageLoader, showErrorModal } = this.state;
    const { homepage, lightBlueBg, subscriptionPage } = this.props;
    const webView = helpers.isWeb();
    let checkDesktop = this.props.sidePanel
      ? false
      : helpers.isDesktop
      ? true
      : false;
    let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
    return (
      <View
        style={[
          checkDesktop && !homepage && Padding.pt_4,
          !subscriptionPage && appStyles.appBg,
          subscriptionPage && { paddingTop: 0 },
          Flex.fill,
          lightBlueBg && { backgroundColor: "#E5E5E5" },
          darkMode && { backgroundColor: Colors.darkBlackColorBg },
          homepage && {
            backgroundColor: darkMode ? Colors.darkBlackColorBg : "#ffffff",
          },
        ]}
      >
        {pageLoader
          ? this.showPageLoader()
          : webView
          ? this.renderWebPage()
          : this.renderAppPage()}
        {showErrorModal && (
          <ErrorModal
            goToHome={() => this.props.navigateToScreen("/")}
            tryAgain={() => this.getModalClickEvent()}
            show={showErrorModal}
          />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ removeUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(SubscriptionPricingPlan));
